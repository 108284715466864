
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: unset;
    }
    &:focus {
        outline: none;
    }
}

ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

// body {
//     color: #000000;
//     font-family: 'Arial', sans-serif;
// }

button {
    border: none;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: initial;
    margin-bottom: 0;
    font-style: initial;
}

p {
    font-size: 14px;
    margin-bottom: 0;
    font-style: initial; 
}

body {
    height: auto;
}