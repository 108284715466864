.company-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px 16px;
    width: 200px;
}

.company-page {
    .input-search {
        max-width: 250px;
    }
}

.company-input-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px 16px; 
    height: inherit;
}

.company-request-status {
    // max-width: 67px;
    // width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
}

.license-company-detail {
    padding: 16px;
    background: #EAEAEA;
    border-radius: 8px;
}

.license-create {
    .form-create {
        padding: 12px 20px;
        max-width: 688px;
        margin: 0 auto;
    }
    .footer-form {
        padding: 20px;
        text-align: center;
    }
    .input-form{
        padding: 8px 16px;
    }
}

.radio-label {
    span {
        font-size: 16px;
        color: #272122;
    }
}

.license-detail {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px 16px;
}

.license-detail-title {
    color: #1C48E7;
    font-weight: 700;
    font-size: 16px;
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 12px;
}

.license-detail-content {
    padding-top: 16px;
    display: flex;
    flex-wrap: wrap;
    .labe {
        color: #000000;
        font-weight: 700;
        font-size: 16px;
    }
}