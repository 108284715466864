.constuction-list-header {
    display: flex;
    padding: 5px 0;
}
.constuction-list-content {
    padding-top: 20px;
    border-top: 2px solid #BEBEBE;
    border-bottom: 2px solid #BEBEBE;
    .content-item {
        display: flex;
        padding:0px;
        padding-bottom: 20px;
    }
    .item-name {
        display: flex;
        align-items: center;
        &-text {
            color: #0052CC;
        }
        &-icon {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }
    .item-location {
        color: #091E42;
    }
}

.construction-create {
    .form-create {
        padding: 12px 20px;
        max-width: 688px;
        margin: 0 auto;
    }
    .footer-form {
        padding: 20px;
        text-align: center;
    }
}

.constuction-list {
    .site-name {
        font-weight: 400;
        font-size: 16px;
        color: #1C48E7;
    }
}

.construction-detail {
    &-title {
       border-bottom: 1px solid #BEBEBE;
       padding: 0px;
       padding-bottom: 10px;
    }
    .btn-edit {
        min-width: 128px;
    }
    .page-content-detail-title {
        border: none;
        padding: 0px;
    }
}

.construction-detail-infor {
    padding: 12px 20px;
    max-width: 688px;
    margin: 0 auto;
    .input-form {
        background: #EAEAEA;
        color: #818181;
    }
}