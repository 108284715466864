.login, .payment {
    &__form {
        padding: 40px 20px;
        width: 512px;;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            background: linear-gradient(165deg, rgba(21, 29, 49, 0.56) -8.22%, rgba(21, 29, 49, 0.00) 113.51%);
            backdrop-filter: blur(6.5px);
            border-radius: 10px;
        }
        &-content {
            z-index: 2;
            position: relative;
        }
    }
    .form-title {
        text-align: center;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 32px;
    }
    &__form__guide {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 8px;
        a {
            color: #1C48E7;
            font-weight: 700;
            text-decoration: revert;
        }
    }
    &__bgr {
        height: 100vh;
        object-fit: fill;
    }
    .form-footer {
        padding:24px 8px 8px;
        border-top: 1px solid #C0C0C0;
    }
}

.login__form {
    display: flex;
    max-height: 504px;
    align-items: center;
    &-content {
        width: 100%;
    }
}
.login__form__input {
    border: 1px solid #C0C0C0;
    background: #FFFFFF;
    padding: 19px 32px;
    border-radius: 8px;
    padding: 12px 16px;
    .ant-input-prefix {
        margin-right: 8px;
    }
    .ant-input {
        font-size: 16px;
    }
    .ant-input-password-icon.anticon {
        svg {
            width: 24px;
            height: 24px;
        }
    }
}

.login__form__input .ant-input::placeholder {
    color: #818181;
}

.login__form__button {
    background: #1C48E7;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    min-height: 48px;
    border: none;
}