.faq_item {
  border-bottom: 1px solid #c7d2e2;
  margin-bottom: 31px;
  padding-bottom: 27px;

  .faq_click {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .question {
        cursor: pointer;
        width: 40px;
        height: 59px;
      }

      .main {
        margin-left: 20px;
        text-align: left;
        .main_title {
          color: #fff;
          // font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          margin: 15px 0;
          cursor: pointer;
        }
      }
    }

    .show_hide {
      .show_hide_item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 26px;
        margin-top: 17px;
      }
    }
  }

  .main_content {
    color: #fff;
    // font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 15px;
    padding-right: 15px;
    margin: -5px 10px 0 58px;
    text-align: left;
    overflow-wrap: break-word;

    &.main_content_scroll {
      max-height: 81px;
      overflow-y: auto;
    }
  }

  #style_scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(24 144 250);
    border-radius: 10px;
    background-color: transparent;
  }

  #style_scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  #style_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #096dd9;
    background-color: transparent;
  }
}

@media screen and (max-width: 575px) {
  .faq_item {
    .faq_click {
      .text {
        .question {
          width: 20px;
          height: 30px;
        }

        .main {
          margin-left: 8px;
          .main_title {
            font-size: 14px;
            line-height: 21px;
            margin: 3px 0;
          }
        }
      }
      .show_hide .show_hide_item {
        width: 19px;
        height: 20px;
        margin-top: 4px;
        align-items: unset;
      }
    }

    .main_content {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0;
      padding-right: 10px;
      margin: 0px 7px 0 27px;
    }
  }
}
