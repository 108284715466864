.header {
    background: #fff;
    padding: 12px 16px;
    position: sticky;
    z-index: 1000;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    left: 0;
    right: 0;
    top: 0;
    min-height: 69px;
    &__icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
        &-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
}

.user-infor {
    top: 72px !important;
    min-width: 280px !important;
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    .ant-dropdown-menu, .ant-dropdown-menu-item {
        padding: 0px;
        border-radius: 8px;
    }
    .ant-dropdown-menu-item:hover {
        background: #E0E9FF;
        .user-infor-item {
            background: #E0E9FF;
            color: #1C48E7;
        }
        .menu-icon {
            path {
                stroke: #1C48E7;
            }
        }
        .menu-icon-spec {
            path {
                fill: #1C48E7;
            }
        }
    }
    &-item {
        display: flex;
        padding: 16px 20px;
        font-size: 16px;
        color: #2E2E2E;
        img {
            margin-right: 10px;
        }
    }
}

.menu-item {
    font-weight: 700;
    font-size: 16px;
    color: #2E2E2E;
    padding: 0 20px;
    transition: all 0.3s;
    &:hover {
        color: #1C48E7;
    }
}

.menu-item-active {
    color: #1C48E7;
}