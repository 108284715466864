.constuction-list-header {
    display: flex;
    padding: 5px 0;
}
.constuction-list-content {
    padding-top: 20px;
    border-top: 2px solid #BEBEBE;
    border-bottom: 2px solid #BEBEBE;
    .content-item {
        display: flex;
        padding:0px;
        padding-bottom: 20px;
    }
    .item-name {
        display: flex;
        align-items: center;
        &-text {
            color: #0052CC;
        }
        &-icon {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }
    .item-location {
        color: #091E42;
    }
}

.button__bgr-blue {
    padding: 0px 5px;
    width: 108px;
    height: 32px;
    background: #0052CC;
    border-radius: 3px;
    color: #FFFFFF;
}

.button-back {
    position: absolute;
    right: -45px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.location-create {
    .form-create {
        max-width: 688px;
        margin: 0 auto;
    }
    .labe-form {
        margin: 4px;
    }
    .footer-form {
        display: flex;
        
    }
}

.upload-sima .ant-upload {
    display: none;
}