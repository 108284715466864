.wrap_faq {
  .faq_top {
    padding: 35.5px 60px;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    margin-bottom: 64px;

    p {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      color: #2b2b2b;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;

      img {
        margin-right: 32px;
      }
    }
  }

  .faq_content {
    margin-bottom: 15px;

    .control {
      margin-bottom: 64px;

      .control_title {
        color: #2b2b2b;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 58px;
        margin-bottom: 32px;
      }

      .control_content {
        list-style-type: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .control_content_item {
          flex: 0 0 25%;
          display: flex;
          align-items: flex-end;
          border: 1px solid #fff;
          background: #ccc;

          display: flex;
          padding: 16px 8px;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          color: #fff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;

          &:hover {
            background: #1c48e7;
          }
        }

        .active {
          background: #1c48e7;
        }
      }
    }

    .content {
      .content_item {
        margin-bottom: 64px;

        .title {
          color: #1c48e7;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          position: relative;
          margin-bottom: 54px;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -8px;
            height: 6px;
            width: 130px;
            background: #1c48e7;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .wrap_faq .faq_content .control .control_content .control_content_item {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 575px) {
  .wrap_faq {
    .faq_top {
      padding: 16px;
      margin-bottom: 16px;
      p {
        font-size: 18px;
        line-height: 27px;
        img {
          margin-right: 8px;
          width: 22px;
          height: 14px;
        }
      }
    }

    .faq_content {
      padding: 0 16px;

      .control {
        margin-bottom: 32px;
        .control_title {
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 16px;
        }
        .control_content .control_content_item {
          padding: 18px 16px;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .content .content_item .title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;

        &::before {
          bottom: -4px;
          height: 4px;
          width: 80px;
        }
      }
    }
  }
}
