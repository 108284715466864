.footer_lp {
  display: flex;
  background: #292929;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 25px 60px;
  margin-top: -1px;

  .footer_left {
    display: flex;
    align-items: center;
    gap: 60px;

    .logo {
      flex: 1.95;
      display: flex;
      align-items: center;
      // font-family: 'Inter', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      color: #fff;
      cursor: pointer;

      img {
        margin-right: 12px;
        width: 50px;
        height: 50px;
      }
    }

    .contact_item {
      display: flex;
      align-items: center;

      .img_icon {
        margin-right: 9px;
        padding: 8px;
        border-radius: 50%;
        background-color: #fff;

        img {
          width: 23px;
          height: 23px;
        }
      }

      .text {
        .text_title {
          color: #9b9b9b;
          // font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }

        .text_content {
          color: #fff;
          // font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
        }
      }
    }
  }

  .footer_right {
    .menu {
      display: flex;
      padding-right: 0px;
      align-items: center;
      gap: 40px;

      .menu_item {
        color: #fff;
        // font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .footer_lp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 40px 16px;
    align-items: flex-start;

    .footer_left {
      display: block;

      .logo {
        margin-bottom: 24px;
        font-size: 25px;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .contact_item {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .footer_right {
      .menu {
        display: block;
        -moz-column-count: 2;
        -moz-column-gap: 32px;
        -webkit-column-count: 2;
        -webkit-column-gap: 32px;
        column-count: 2;
        column-gap: 32px;

        .menu_item {
          margin-bottom: 16px;
          font-size: 14px;
          line-height: 21px;
        }

        li:nth-child(5) {
          -webkit-column-break-after: column;
          break-after: column;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .footer_lp {
    display: block;
    padding: 32px 16px;

    .footer_left {
      display: block;
      margin-bottom: 24px;

      .logo {
        margin-bottom: 28px;
        font-size: 26px;

        img {
          width: 47px;
          height: 48px;
        }
      }

      .contact_item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .text .text_title,
        .text_content {
          font-size: 14px;
          line-height: 21px;
        }

        .img_icon {
          margin-right: 8px;
          padding: 4px 7px;

          img {
            width: 16px;
          }
        }
      }
    }

    .footer_right {
      .menu {
        display: block;
        -moz-column-count: 2;
        -moz-column-gap: 32px;
        -webkit-column-count: 2;
        -webkit-column-gap: 32px;
        column-count: 2;
        column-gap: 32px;

        .menu_item {
          margin-bottom: 16px;
          font-size: 14px;
          line-height: 21px;
        }

        li:nth-child(5) {
          -webkit-column-break-after: unset;
          break-after: unset;
          margin-bottom: 16px;
        }
      }
    }
  }
}
