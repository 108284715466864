.location__list__file {
    .block-file-location {
        padding: 0px;
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 16px;
    }
    .lable-file {
        padding:12px 16px;
        font-weight: 700;
        font-size: 16px;
        color: #1C48E7;
        border-bottom: 1px solid #EAEAEA;
        display: inline-flex;
        align-items: center;
        width: 100%;
        .add-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
    .button-add-file {
        min-width: 80px;
        min-height: 33px;
    }
    .list-file {
        &-item {
            display: flex;
            padding: 12px;
            background: #fff;
            .ant-spin-container {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
            .ant-spin-nested-loading {
                width: 100%;
            }
        }
        &-item:nth-child(2n + 1) {
            background: #EAEAEA;
        }
        &-item:last-child {
            border-radius: 0px 0px 8px 8px
        }
        .file-name {
            color: #2E2E2E;
            font-size: 16px;
        }
        .file-icon {
            display: flex;
            img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
}

.overload__management, .mix__tonnage__setting {
    padding: 20px;
}

.overload__management, .mix__tonnage__setting, .setting_diagram {
    .tonnage__setting {
        font-weight: 700;
        font-size: 16px;
        color: #1C48E7;
        padding: 16px;
        border-bottom: 1px solid #EAEAEA;
    }
    .tonnage-total {
        padding: 8px 38px;
        border-bottom: 1px solid #EAEAEA;
    }
    .list__tonnages {
        font-size: 16px;
        color: #091E42;
        padding: 8px;
        padding-left: 78px;
        .tonnage {
            margin-bottom: 16px;
            color: #2E2E2E;
        }
    }
    .input__tonnage {
        margin: 0 24px;
        max-width: 96px;
        border-radius: 3px;
        font-size: 16px;
        color: #2E2E2E;
        border-radius: 8px;
        padding: 4px 16px;
        &-total {
            min-width: 200px;
            border-radius: 8px;
            padding: 4px 12px;
            font-size: 16px;
            color: #2E2E2E;
        }
    }
    .cutting-thickness-file {
        width: 228px;
        font-size: 16px;
        .ant-select-arrow {
            right: 3px;
        }
    }
    .amount-mixture {
        padding: 8px 12px;
        display: flex;
        align-items: center;
    }
    .pavement-radio-lable {
        font-size: 16px
    }
}
.setting_diagram .amount-mixture {
    align-items: normal;
}

.setting_diagram .cutting-thickness-file {
    width: 100%;
}

.setting_diagram .basic__settings__item {
    justify-content: space-between;
}

.mix__tonnage__setting .basic__settings{
    padding: 8px 12px 16px 12px;
}

.mix__tonnage__setting, .setting_diagram {
    .basic__settings__item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        color: #2E2E2E;
        .settings__item__lable, span {
            font-size: 16px;
        }
        .input__tonnage {
            margin-left: 0px;
        }
        .ant-radio-group {
            span {
                color: #2E2E2E;
            }
        }
    }
    .thickness-input-method {
        align-items: start;
        .input__tonnage {
            margin-right: 15px;
        }
    }
    .pavement-input-method {
        font-size: 16px;
        color: #2E2E2E;
    }
}

.border-bottom-gray {
    border-bottom: 1px solid #EAEAEA;
}

.ponchie-picture {
    padding: 10px 16px 24px;
    padding-top: 0px;
}

.ponchie-setting {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    .margin-right-5px {
        margin-right: 5px;
    }
    .input-form {
        padding: 8px 16px;
    }
    .input-form.ant-input-number {
        padding: 4px 16px;
    }
    &-header {
        padding: 16px;
        color: #1C48E7;
        border-bottom: 1px solid #EAEAEA;
        font-weight: 700;
        font-size: 16px;
    }
    &-name {
        display: flex;
        padding: 8px 12px;
        border-bottom: 1px solid #EAEAEA;
        label {
            color: #2E2E2E;
            font-size: 16px;
            margin-right: 12px;
            white-space: nowrap;
        } 
    }
    &-content {
        // height: 630px;
        // overflow-y: auto;
    }
    &-content-item {
        padding: 8px 12px 16px;
        border-bottom: 1px solid #EAEAEA;
    }
    &-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 8px 8px;
        .button-add {
            font-size: 16px;
            font-weight: 700;
        }
        .btn-green {
            background: #41A026;
            border-color: #41A026;
            &:hover, &:active, &:focus {
                border-color: #41A026;
                color: #41A026;
                background: #FFFFFF;
            }
        }
    }
    .border-input {
        border: 1px solid #C0C0C0;
        border-radius: 8px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        min-height: 40px;
        .ant-select-selection-item {
            font-size: 16px;
        }
    }
    .data-point {
        min-height: 92px;
        transition: all 0.6s;
    }

    .ponchie-block {
        transition: all 0.6s;
    }
    .data-pits {
        min-height: 196px;
        transition: all 0.6s;
    }

    .hiden-block {
        margin: 0;
        height: 0px;
        overflow: hidden;
        min-height: 0px;
    }
    .drop-arrow {
        transition: all 0.6s;
    }
}

.ponchie-setting-button {
    display: flex;
    .button-form {
        padding: 8px 12px;
        color: #1C48E7;
        font-weight: 600;
        border: 1px dashed #C0C0C0;
    }
    .button-add {
        padding: 8px 12px;
        color: #fff;
        font-weight: 600;
        width: 50%;
        height: auto;
    }
}
// .cutting-order-diagram-content {
//     height: 512px;
// }
.layout-sprin {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    min-height: 800px;
}

.input-color-group {
    display: flex;
    padding: 3px 5px;
    border: 1px solid #C0C0C0;
    border-radius: 8px;
    width: 100%;
    align-items: center;
    .css-1yn2e29-InputColor {
        width: 32px;
        height: 32px;
    }
}

.ponchie-canvas {
    .canvas-container {
        margin: 0 auto;
    }
}

.ponchie-setting-name {
    .input-form {
        padding: 8px 16px;
    }
}

.modal-preview-punchie .ant-modal-body {
    padding: 30px;
}

.modal-preview-custom .ant-modal-body{
    padding: 40px;
}

.modal-preview-punchie .ant-modal-close-x {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ml-0px {
    margin-left:0px !important;
}

.unit-input {
    position: absolute;
    top: 8px;
    left: 114px;
    white-space: nowrap;
}

// .modal-excel-content {
//     position: relative;
// }

// .modal-excel-content::after {
//     content: '';
//     position: absolute;
//     left: 60px;
//     top: 25px;
//     bottom: 16px;
//     right: 0;
//     z-index: 9999;
// }

// .modal-excel-content:hover {
//     &::after {
//         cursor: no-drop;
//     } 
// }

.amount-mixture {
    .unit-input {
        left: 220px;
    }
}

.overload__tonnage {
    .input__tonnage {
        margin-left: 0px;
    }
    .unit-input {
        left: 174px;
    }
}

.group-sima-base {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
}

.modal-upload-sima .ant-modal-content {
    border-radius: 8px;
}

.upload-sima-base {
    .labe-upload {
        color: #2E2E2E;
        font-size: 16px;
        font-weight: 700;
        display: inline-block;
        span {
            color: #CA3F4B;
        }
    }
}

.file-name-edit {
    background: #1C48E7;
    padding: 12px 16px;
    border-radius: 8px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
}

.mix__tonnage__setting.thickness-diagram {
    padding-right: 0px;
}

.mix__tonnage__setting.thickness-diagram .cutting-thickness-file {
    width: 100%;
}

.mix__tonnage__setting.thickness-diagram .cutting-thickness-file .ant-select-arrow{
    right: 11px;
}

.form-diagram {
    overflow-y: auto;
    height:  calc(100vh - 245px);
    &-content {
        height: calc(100vh - 205px);
    }
}

.form-ponchie {
    overflow-y: auto;
    height: calc(100vh - 262px);
    &-content {
        height: calc(100vh - 200px);
    }
}

.form-diagram, .form-ponchie {
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(216, 214, 214, 0.993);
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(200, 200, 200);
    }
}

.hiden-setting {
    height: 0px;
}
.toggle-setting {
    transition: all 0.6s;
}

.minimap {
    border: 1px solid #000000;
    position: absolute !important;
    top: 20px;
    right: 20px;
}

.minimap_preview {
    border: 1px solid #000000;
    position: absolute !important;
    top: 30px;
    right: 30px;
}