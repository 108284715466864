.payment {
    margin-top: 0;
}

.payment__form {
    .form-title {
        font-size: 32px;
    }
    &::after {
        background: linear-gradient(138deg, rgba(21, 29, 49, 0.56) 0%, rgba(21, 29, 49, 0.00) 100%);
    }
    .label {
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 4px;
        display: inline-block;
    }
    .card-input {
        height: 43px;
        background: #FFFFFF;
        border: 1px solid #C0C0C0;
        border-radius: 8px;
        padding: 12px 16px;
        color: #272122;
        font-size: 16px;
        iframe {
            height: 19px !important;
        }
    }
    .input-form {
        height: 43px;
        &::placeholder {
            color: #818181;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.terms-service {
    margin: 0 auto;
    margin-bottom: 32px;
    width: 928px;
    padding: 30px 0px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    .title {
        font-size: 16px;
        font-weight: 700;
        color: #1C48E7;
    }
    .content-title {
        font-size: 16px;
        font-weight: 700;
        color: #2E2E2E;
        margin-bottom: 5px;
    }
}

.terms-checkbox {
    display: flex;
    align-items: center;
}

.terms-checkbox .ant-checkbox-inner{
    width: 24px;
    height: 24px;
    border-color: #2E2E2E;
    border-radius: 5px;
    border-width: 1px;
}

.terms-checkbox .ant-checkbox-inner::after{
    left: 30.5%;
}

.terms-checkbox .ant-checkbox-checked::after {
    border-radius: 5px;
}

.terms-checkbox .ant-checkbox {
    top: 0px;
}

.terms-service-btn .button-form {
    width: 468px;
}

.modal-success .ant-modal-content{
    border-radius: 8px;
}