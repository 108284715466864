.wrap_lp {
  p {
    margin: 0;
  }

  .header_lp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 60px;
    width: 100%;
    gap: 20px;
    position: fixed;
    top: 0;
    z-index: 1000;
    transition: all 0.3s;

    &.header_lp_black {
      background: #3c3c3c;
    }

    .logo {
      flex: 1.95;
      display: inline-flex;
      align-items: center;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      cursor: pointer;
      position: relative;
      z-index: 1000;

      img {
        margin-right: 10px;
        width: 45px;
      }
    }

    .logo_blue {
      color: #253858;
    }

    .nav_btn {
      border-radius: 4px;
      background: #1c48e7;
      box-shadow: 0px 4px 10px 0px rgba(28, 72, 231, 0.5);
      padding: 14px;
      position: relative;
      z-index: 1000;
    }

    .menu_button {
      display: flex;
      align-items: center;
      gap: 20px;

      .menu {
        display: inline-flex;
        align-items: center;
        gap: 40px;
        color: #fff;
        // font-family: 'Inter', sans-serif;

        .menu_item {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
        }

        .language {
          position: relative;
          .language_base {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            .language_globe {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }

          .language_popup {
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);

            width: 100%;
            border: 1px solid #bdbdbd;
            border-radius: 5px;
            background-color: #fff;

            .language_popup_item {
              font-size: 14px;
              color: #212529;
              text-align: left;
              padding: 10px 20px;
              border-radius: 5px;
              cursor: pointer;

              &:hover {
                background: #1c48e7;
                color: #ffffff;
              }
            }
          }
        }
      }

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }

      .button_login {
        color: #fff;
        // font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        background-color: #1c48e7;
        padding: 13px 28px;
        border-radius: 4px;
      }
    }

    .menu_mobile {
      right: -100%;
      display: block;
      position: fixed;
      top: 0px;
      background: rgb(255, 255, 255);
      height: 100vh;
      width: 100%;
      overflow: auto;
      transition: all 0.3s ease 0s;
      z-index: 100;

      padding: 80px 16px 40px;

      .line {
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background: #dcdcdc;
      }

      .wrap_login {
        .wrap_avatar {
          display: flex;
          align-items: center;

          .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 12px;
            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
            }
          }

          .avatar_info {
            .name {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }

            .mail {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
            }
          }
        }

        .button_login {
          color: #fff;
          // font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          background-color: #1c48e7;
          padding: 13px 28px;
          border-radius: 4px;
          width: fit-content;
          box-shadow: 0px 4px 10px 0px rgba(28, 72, 231, 0.5);
        }
      }

      .menu {
        color: #2b2b2b;
        // font-family: 'Inter', sans-serif;
        display: grid;
        gap: 16px;

        .menu_item {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          cursor: pointer;
        }
      }

      .language {
        position: relative;
        .language_base {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .language_globe {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }

        .language_popup {
          position: absolute;
          top: 27px;
          left: 0;
          width: fit-content;
          border: 1px solid #bdbdbd;
          border-radius: 5px;
          background-color: #fff;

          .language_popup_item {
            font-size: 14px;
            color: #212529;
            text-align: left;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background: #1c48e7;
              color: #ffffff;
            }
          }
        }
      }

      .go_to_cms {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      }

      .logout {
        color: #da3131;
        // font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        display: inline-flex;
        align-items: center;

        img {
          margin-right: 8px;
        }
      }
    }

    .menu_mobile_show {
      right: 0;
    }
  }

  .body_lp {
    .banner {
      background-image: url('../../../../src/assets/images/landingPage/banner_background.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      padding-top: 147px;
      text-align: center;
      color: #ffffff;

      &.banner_tablet {
        background-image: url('../../../../src/assets/images/landingPage/banner_bg_tablet.png') !important;
      }
      &.banner_mobile {
        background-image: url('../../../../src/assets/images/landingPage/banner_bg_mobile.png') !important;
      }

      .title_banner {
        // font-family: 'Inter', sans-serif;
        font-size: 70px;
        font-weight: 700;
        line-height: 101px;
        text-align: center;

        margin-bottom: 18px;
      }

      .des_banner {
        text-align: center;
        // font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-bottom: 43px;
      }

      .img_app_store {
        display: flex;
        justify-content: center;
      }

      .img_banner {
        display: flex;
        justify-content: center;
      }
    }

    .main_function {
      background-color: #ffffff;
      padding-top: 50px;
      margin-bottom: 180px;
      text-align: center;

      .title {
        color: #1c48e7;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 100px;
        position: relative;
        margin-bottom: 108px;
        display: inline-flex;
        justify-content: center;

        .rectangle {
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          width: 130px;
          height: 6px;
          background-color: #1c48e7;
        }
      }

      .wrap_content {
        position: relative;
        padding: 190px 0 222px;

        .img_center {
          position: relative;
          display: flex;
          justify-content: center;
        }

        .wrap_content_item {
          position: absolute;
          max-width: 392px;

          .content_item {
            position: relative;

            .content_item_img {
              display: flex;
              justify-content: center;
            }

            .content_item_text {
              transform: translateY(-30px);

              .content_item_icon {
                display: flex;
                justify-content: center;
              }

              p {
                align-items: center;
              }

              .content_item_title {
                color: #1c48e7;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;
                margin-bottom: 16px;
              }

              .content_item_desc {
                color: #2b2b2b;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
              }
            }

            .content_item_arrow {
              position: absolute;
            }
          }
        }

        .wrap_content_item_left_1 {
          top: 0;
          left: 0;

          .content_item {
            .content_item_arrow {
              right: -85px;
              bottom: 150px;
            }
          }
        }
        .wrap_content_item_left_2 {
          bottom: -40px;
          left: 0;

          .content_item {
            .content_item_arrow {
              right: -70px;
              top: -10px;
            }
          }
        }
        .wrap_content_item_right_1 {
          top: 0;
          right: 0;

          .content_item {
            .content_item_arrow {
              left: -85px;
              bottom: 80px;
            }
          }
        }
        .wrap_content_item_right_2 {
          bottom: -40px;
          right: 0;

          .content_item {
            .content_item_arrow {
              left: -70px;
              top: -5px;
            }
          }
        }
      }
    }

    .control {
      background-image: url('../../../../src/assets/images/landingPage/cms_engineer_bg.png');
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      padding: 252px 0 20px;

      &.control_mobile {
        background-image: url('../../../../src/assets/images/landingPage/cms_engineer_bg_mb.png') !important;
      }

      .control_inside {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;

        .control_text {
          .title {
            color: #fff;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            margin-bottom: 44px;
            position: relative;
            display: inline-block;

            .rectangle {
              position: absolute;
              bottom: -8px;
              left: 0;
              width: 130px;
              height: 6px;
              background-color: #fff;
            }
          }

          .slogan_content {
            p {
              color: #fff;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .control_img {
          display: flex;
          justify-content: center;
        }
      }

      .wrap_engineer {
        .engineer {
          padding: 326px 0 426px;
          position: relative;

          .engineer_img_center {
            position: absolute;
            top: 47%;
            left: 42%;
            transform: translate(-50%, -50%);
          }

          .engineer_content_item {
            position: absolute;
            max-width: 448px;

            .icon_title {
              margin-bottom: 16px;

              span {
                color: #fff;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;
              }
            }

            .desc {
              color: #fff;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 27px;
            }
          }

          .engineer_content_item_1 {
            right: 8%;
            top: 14%;
          }

          .engineer_content_item_2 {
            right: -1%;
            top: 36%;
          }

          .engineer_content_item_3 {
            right: 1%;
            bottom: 32%;
          }

          .engineer_content_item_4 {
            right: 19%;
            bottom: 13%;
          }
        }
      }
    }

    .faq {
      padding: 135px 0 129px;
      text-align: center;
      position: relative;
      z-index: 5;
      margin-top: -80px;

      .title {
        color: #1c48e7;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 100px;
        position: relative;
        margin-bottom: 108px;
        display: inline-flex;
        justify-content: center;

        .rectangle {
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          width: 130px;
          height: 6px;
          background-color: #1c48e7;
        }
      }

      .content {
        margin-bottom: 60px;
      }

      .button_see_more {
        display: flex;
        justify-content: center;

        button {
          color: #1c48e7;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 27px;
          text-decoration-line: underline;
        }
      }
    }

    .notice {
      background-image: url('../../../../src/assets/images/landingPage/notice_bg.png');
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      text-align: center;
      padding: 208px 0 234px;

      &.notice_mobile {
        background-image: url('../../../../src/assets/images/landingPage/notice_bg_mb.png') !important;
      }

      .title {
        color: #fff;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 100px;
        position: relative;
        margin-bottom: 54px;
        display: inline-flex;
        justify-content: center;

        .rectangle {
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          width: 130px;
          height: 6px;
          background-color: #fff;
        }
      }

      .event {
        margin-bottom: 48px;

        .event_item {
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 1px solid #fff;
          display: flex;
          align-items: flex-start;

          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: unset;
          }

          .event_item_date {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 27px;
            margin-right: 32px;
          }

          .event_item_content {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            text-align: left;
            white-space: pre-line;
          }
        }
      }

      .button_see_more {
        display: flex;
        justify-content: center;

        button {
          color: #fff;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 27px;
          text-decoration-line: underline;
        }
      }
    }

    .wrap_contact {
      padding: 130px 0 110px;
      text-align: center;

      .contact {
        .title {
          color: #1c48e7;
          font-size: 60px;
          font-style: normal;
          font-weight: 700;
          line-height: 100px;
          position: relative;
          margin-bottom: 38px;
          display: inline-flex;
          justify-content: center;

          .rectangle {
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            width: 130px;
            height: 6px;
            background-color: #1c48e7;
          }
        }

        .des {
          color: #2b2b2b;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 56px;
        }

        .wrap_form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 32px;
          margin-bottom: 24px;

          .form_img {
            border-radius: 8px;
            height: 100%;
            width: 100%;

            img {
              border-radius: 8px;
              height: 100%;
              width: 100%;
            }
          }

          .form {
            padding: 32px 32px 40px;
            border-radius: 8px;
            background: #e9eeff;

            .form_item {
              margin-bottom: 16px;
              text-align: left;

              .label_form {
                color: #2b2b2b;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 21px;
                margin-bottom: 4px;
                display: inline-block;

                span {
                  color: #da3131;
                }
              }

              .input_form {
                height: 40px;
                padding: 10px;
                border-radius: 5px;
              }

              .input_form_textarea {
                height: 85px;
                max-height: 85px;
              }
            }

            .wrap_recaptcha {
              display: flex;
              justify-content: center;
            }

            .button_submit {
              margin-top: 40px;
              display: flex;
              justify-content: center;

              button {
                padding: 12px 30px;
                border-radius: 4px;
                background: #1c48e7;
                box-shadow: 0px 4px 10px 0px rgba(28, 72, 231, 0.5);
                border: unset;
                height: 50px;

                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;

                display: flex;
                align-items: center;
                justify-content: center;

                &:disabled {
                  background: #1c48e799;
                }
              }
            }
          }
        }

        .message_fn {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 3fr;
          gap: 32px;
          padding: 24px 0;
          border-radius: 8px;
          background: linear-gradient(283deg, rgba(28, 72, 231, 0.93) -21.21%, rgba(99, 214, 244, 0.93) 104.88%);

          .message_icon {
            display: flex;
            justify-content: flex-end;
          }

          .message_text {
            text-align: left;

            .message_text_title {
              color: #fff;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              position: relative;
              margin-bottom: 28px;
              display: inline-flex;
              justify-content: center;

              .rectangle {
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 130px;
                height: 4px;
                background-color: #fff;
              }
            }

            .message_text_des {
              .message_text_des_item {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  .footer_lp {
    display: flex;
    background: #292929;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 25px 60px;
    margin-top: -1px;

    .footer_left {
      display: flex;
      align-items: center;
      gap: 60px;

      .logo {
        flex: 1.95;
        display: flex;
        align-items: center;
        // font-family: 'Inter', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        color: #fff;
        cursor: pointer;

        img {
          margin-right: 12px;
          width: 50px;
          height: 50px;
        }
      }

      .contact_item {
        display: flex;
        align-items: center;

        .img_icon {
          margin-right: 9px;
          padding: 8px;
          border-radius: 50%;
          background-color: #fff;

          img {
            width: 23px;
            height: 23px;
          }
        }

        .text {
          .text_title {
            color: #9b9b9b;
            // font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
          }

          .text_content {
            color: #fff;
            // font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
          }
        }
      }
    }

    .footer_right {
      .menu {
        display: flex;
        padding-right: 0px;
        align-items: center;
        gap: 40px;

        .menu_item {
          color: #fff;
          // font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

.wrap_lp_lang {
  .body_lp {
    .banner {
      .title_banner {
        line-height: 78px;
      }
    }

    .main_function {
      .title {
        line-height: 85px;
      }

      .wrap_content {
        .wrap_content_item {
          .content_item {
            .content_item_text {
              .content_item_desc {
                line-height: 24px;
              }
            }
          }
        }

        .wrap_content_item_right_1 {
          top: 0;
          right: 0;

          .content_item {
            .content_item_arrow {
              left: -85px;
              bottom: 115px;
            }
          }
        }
      }
    }

    .control {
      .control_inside {
        .control_text {
          .title {
            line-height: 65px;
          }
        }
      }

      .wrap_engineer {
        .engineer {
          .engineer_content_item {
            .icon_title {
              span {
                line-height: 32px;
              }
            }
          }

          .engineer_content_item_1 {
            right: 6%;
          }

          .engineer_content_item_2 {
            right: -2%;
            top: 36%;
          }

          .engineer_content_item_3 {
            right: 0%;
            bottom: 28%;
          }
        }
      }
    }
  }
}

.user-infor .ant-dropdown-menu-item:hover .user-infor-item {
  border-radius: 8px;
}

@media screen and (max-width: 1650px) {
  .wrap_lp {
    .body_lp .control .wrap_engineer .engineer .engineer_content_item_2 {
      right: 0%;
    }

    .footer_lp {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      padding: 40px 16px;
      align-items: flex-start;

      .footer_left {
        display: block;

        .logo {
          margin-bottom: 24px;
          font-size: 25px;

          img {
            width: 40px;
            height: 40px;
          }
        }

        .contact_item {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .footer_right {
        .menu {
          display: block;
          -moz-column-count: 2;
          -moz-column-gap: 32px;
          -webkit-column-count: 2;
          -webkit-column-gap: 32px;
          column-count: 2;
          column-gap: 32px;

          .menu_item {
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 21px;
          }

          li:nth-child(5) {
            -webkit-column-break-after: column;
            break-after: column;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .wrap_lp {
    .header_lp {
      padding: 10px 16px;

      .menu_button {
        .menu {
          gap: 24px;
        }
      }
    }

    .body_lp {
      .banner {
        min-height: 991px;

        .title_banner {
          font-size: 32px;
          line-height: 48px;
          margin-bottom: 16px;
        }

        .des_banner {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 41px;
        }
      }

      .main_function {
        padding-top: 39px;
        margin-bottom: 90px;

        .title {
          font-size: 40px;
          line-height: normal;
          margin-bottom: 55px;
        }

        .wrap_content {
          padding: 160px 0;

          .wrap_content_item {
            max-width: 280px;

            .content_item {
              .content_item_arrow {
                width: 100px;
              }
            }
          }

          .wrap_content_item_left_1 .content_item .content_item_arrow {
            right: -65px;
            bottom: 175px;
          }

          .wrap_content_item_right_1 .content_item .content_item_arrow {
            left: -65px;
            bottom: 110px;
          }

          .wrap_content_item_right_2 {
            bottom: -65px;
          }
        }
      }

      .control {
        padding: 106px 0 129px;

        .control_inside {
          display: block;

          .control_text {
            margin-bottom: 20px;
            .title {
              font-size: 50px;
            }

            .slogan_content {
              p {
                font-size: 23px;
              }
            }
          }
        }

        .wrap_engineer {
          .engineer {
            padding: 60px 0 0;

            .engineer_img_left {
              display: flex;
              justify-content: center;
              margin-bottom: 60px;
            }

            .engineer_content_item {
              position: relative;
              top: unset;
              bottom: unset;
              left: unset;
              right: unset;
              max-width: unset;
              margin-bottom: 32px;

              .icon_title {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                span {
                  margin-left: 8px;
                  font-size: 20px;
                }
              }

              .desc {
                font-size: 14px;
              }
            }
          }
        }
      }

      .faq {
        padding: 105px 0;
        margin-top: -22px;

        .title {
          font-size: 50px;
          line-height: normal;
          margin-bottom: 68px;
        }
      }

      .notice {
        padding: 74px 0;

        .title {
          font-size: 40px;
          line-height: normal;
          margin-bottom: 62px;
        }
      }

      .wrap_contact {
        padding: 131px 0 66px;

        .contact {
          .title {
            font-size: 40px;
            line-height: normal;
            margin-bottom: 30px;
          }

          .wrap_form {
            display: block;

            .form_img {
              margin-bottom: 32px;
            }
          }
        }
      }
    }
  }

  .wrap_lp_lang {
    .body_lp {
      .main_function {
        .title {
          font-size: 38px;
          line-height: normal;
          margin-bottom: 55px;
        }

        .wrap_content {
          padding: 160px 0;

          .wrap_content_item {
            max-width: 280px;

            .content_item {
              .content_item_text {
                .content_item_title {
                  font-size: 30px;
                  line-height: 40px;
                }

                .content_item_desc {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .wrap_lp {
    .header_lp {
      .logo {
        font-size: 22px;

        img {
          width: 32px;
        }
      }
    }

    .body_lp {
      .banner {
        min-height: unset;
      }

      .main_function {
        margin-bottom: 80px;

        .wrap_content {
          padding: 180px 0;

          .img_center {
            img {
              width: 200px;
            }
          }

          .wrap_content_item {
            max-width: 215px;

            .content_item .content_item_text {
              transform: translateY(-15px);

              .content_item_icon {
                img {
                  width: 40px;
                }
              }

              .content_item_title {
                font-size: 20px;
              }

              .content_item_desc {
                font-size: 14px;
              }
            }
          }

          .wrap_content_item_right_2 {
            bottom: -60px;
          }
        }
      }

      .faq {
        margin-top: -50px;
      }
    }
  }

  .wrap_lp_lang {
    .body_lp {
      .banner {
        .title_banner {
          line-height: 48px;
        }
      }

      .main_function {
        .wrap_content {
          padding: 180px 0;

          .wrap_content_item {
            .content_item .content_item_text {
              .content_item_title {
                font-size: 18px;
                margin-bottom: 0px;
              }

              .content_item_desc {
                font-size: 13px;
              }
            }
          }
        }
      }

      .control {
        .control_inside {
          .control_text {
            .title {
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .wrap_lp {
    .body_lp {
      .main_function {
        margin-bottom: 0;

        .wrap_content {
          padding: 0;

          .img_center {
            img {
              width: unset;
            }
          }

          .wrap_content_item {
            max-width: unset;
            position: relative;
            top: unset;
            bottom: unset;
            margin-top: -65px;
            margin-bottom: 40px;
          }
        }
      }

      .control {
        padding: 164px 0;
      }

      .faq {
        margin-top: -50px;
        min-height: unset;
      }

      .wrap_contact {
        padding: 65px 0;
      }
    }
  }

  .wrap_lp_lang {
    .body_lp {
      .main_function {
        margin-bottom: 0;

        .wrap_content {
          padding: 0;

          .img_center {
            img {
              width: unset;
            }
          }

          .wrap_content_item {
            max-width: unset;
            position: relative;
            top: unset;
            bottom: unset;
            margin-top: -65px;
            margin-bottom: 40px;
          }
        }
      }

      .control {
        .control_inside {
          .control_text {
            .title {
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .wrap_lp {
    .rectangle {
      width: 100px !important;
      height: 4px !important;
    }

    .header_lp {
      padding: 10px 16px;

      .logo {
        line-height: normal;
      }

      .nav_btn {
        padding: 8px;
      }
    }

    .body_lp {
      .banner {
        padding-top: 86px;
        // min-height: 662px;

        .title_banner {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 8px;
        }

        .des_banner {
          padding: 0 16px;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 26px;
        }

        .img_app_store {
          img {
            width: 108px;
            height: 35px;
          }
        }
      }

      .main_function {
        padding-top: 39px;
        margin-bottom: 57px;
        padding-left: 16px;
        padding-right: 16px;

        .title {
          font-size: 32px;
          line-height: 48px;
          margin-bottom: 20px;
          padding: 0 45px;
        }

        .wrap_content {
          .img_center img {
            max-width: 215px;
            max-height: 470px;
          }

          .wrap_content_item {
            padding: 0 3px;
            .content_item {
              .content_item_img {
                img {
                  max-width: 261px;
                }
              }
              .content_item_text {
                transform: translateY(-30px);
              }
            }
          }
        }
      }

      .control {
        padding: 81px 16px;
        background-position: left center;

        .control_inside {
          .control_text {
            .title {
              font-size: 32px;
              line-height: 48px;
              margin-bottom: 38px;
            }

            .slogan_content {
              p {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }

        .wrap_engineer {
          .engineer {
            .engineer_img_left {
              margin-bottom: 56px;
            }
          }
        }
      }

      .faq {
        padding: 104px 16px 74px;
        margin-top: -40px;

        .title {
          font-size: 32px;
          line-height: 48px;
          margin-bottom: 40px;
        }

        .content {
          margin-bottom: 32px;
        }
      }

      .notice {
        padding: 93px 16px 150px;

        .title {
          font-size: 32px;
          line-height: 48px;
          margin-bottom: 56px;
        }

        .event .event_item {
          padding-bottom: 16px;
          margin-bottom: 16px;

          .event_item_date {
            font-size: 14px;
            line-height: 21px;
            margin-right: 8px;
          }

          .event_item_content {
            font-size: 14px;
            line-height: 21px;
          }
        }

        .button_see_more button {
          font-size: 14px;
          line-height: 21px;
        }
      }

      .wrap_contact {
        padding: 74px 16px 63px;

        .contact {
          .title {
            font-size: 32px;
            line-height: normal;
            margin-bottom: 30px;
          }

          .des {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 32px;
          }

          .wrap_form {
            margin-bottom: 32px;

            .form {
              padding: 24px 20px;

              .button_submit {
                margin-top: 32px;

                div {
                  width: 100%;

                  button {
                    width: 100%;
                  }
                }
              }
            }
          }

          .message_fn {
            display: block;
            padding: 16px 16px 24px;

            .message_icon {
              justify-content: center;
              margin-bottom: 16px;
            }

            .message_text {
              .message_text_title {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 27px;

                .rectangle {
                  width: 80px !important;
                  height: 3px !important;
                }
              }

              .message_text_des .message_text_des_item {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }

    .footer_lp {
      display: block;
      padding: 32px 16px;

      .footer_left {
        display: block;
        margin-bottom: 24px;

        .logo {
          margin-bottom: 28px;
          font-size: 26px;

          img {
            width: 47px;
            height: 48px;
          }
        }

        .contact_item {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .text .text_title,
          .text_content {
            font-size: 14px;
            line-height: 21px;
          }

          .img_icon {
            margin-right: 8px;
            padding: 4px 7px;

            img {
              width: 16px;
            }
          }
        }
      }

      .footer_right {
        .menu {
          display: block;
          -moz-column-count: 2;
          -moz-column-gap: 32px;
          -webkit-column-count: 2;
          -webkit-column-gap: 32px;
          column-count: 2;
          column-gap: 32px;

          .menu_item {
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 21px;
          }

          li:nth-child(5) {
            -webkit-column-break-after: unset;
            break-after: unset;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .wrap_lp_lang {
    .body_lp {
      .main_function {
        .title {
          padding: 0;
        }
      }
    }
  }
}
