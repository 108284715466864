.wrap_policy {
  padding-top: 100px;
  .policy-container {
    border: 5px solid #dedede;
    border-radius: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    margin-top: 46px;

    .plc-title {
      text-align: center;
      h5 {
        font-weight: 700;
        font-size: 24px;
        color: #1a3a6a;
        margin-top: 40px;
      }
    }

    .plc-content {
      padding: 40px 35px 20px;
      .content-item {
        .content-title {
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          border-bottom: 1px solid #000000;
          padding-bottom: 5px;
          margin-bottom: 15px;
        }

        .content-desc {
          margin-bottom: 30px;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          p {
            font-size: 16px;
          }

          .plc-head {
            padding-bottom: 20px;
          }

          .indent {
            padding-left: 2em;

            ul {
              list-style-type: disc;

              li::marker {
                unicode-bidi: isolate;
                font-variant-numeric: tabular-nums;
                text-transform: none;
                text-indent: 0px !important;
                text-align: start !important;
                text-align-last: start !important;
              }

              li {
                .plc-head {
                  padding-bottom: 20px;
                }

                a {
                  text-decoration: none;
                  color: #007bff;
                  display: block;
                  overflow-x: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .wrap_policy {
    padding-top: 50px;
    .policy-container {
      margin: 35px 20px;

      .plc-title h5 {
        margin-top: 20px;
      }

      .plc-content {
        padding: 20px;
      }
    }
  }
}
