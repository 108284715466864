.detail-title {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: #F5F5F5;
    border-bottom: 1px solid #C0C0C0;
    position: fixed;
    width: 100%;
    z-index: 99;
    .page-note-text {
        color: #1C48E7;
        font-weight: 700;
        font-size: 24px;
    }
    .page-detail-note {
        color: #C0C0C0;
        font-weight: 700;
        font-size: 24px;
        text-align: left;
    }
    .text-blue {
        color: #1C48E7;
    }
}
.layout {
    .side-bar {
        max-width: 227px;
        background: #1C48E7;
        padding: 14px 0px 30px;
        min-height: 100vh;
        white-space: nowrap;
        margin-top: 57px;
        position: fixed;
    }
}

.side-bar {
    &-item {
        font-weight: 700;
        font-size: 16px;
        display: flex;
        padding: 16px;
        color: #E0E0E0;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.1s;
        img {
            margin-right: 8px;
      
        }
        &:hover {
            background: #1437C6;
            border-left: 3px solid #fff;
        }
    }
    .active {
        background: #1437C6;
        border-left: 3px solid #fff;
    }
}
.content-layout {
    width: 100%;
    margin-top: 57px;
    margin-left: 227px;
}