.reject-button {
  border: 1px solid #1c48e7;
  border-radius: 8px;
  padding: 8px 12px;
  color: #1c48e7;
  font-weight: 700;
  font-size: 16px;
  width: 128px;
  height: 40px;
}

.accept-button {
  border: 1px solid #1c48e7;
  background: #1c48e7;
  border-radius: 8px;
  padding: 8px 12px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  width: 128px;
  height: 40px;
}

.accept-icon {
  background: rgba(28, 72, 231, 0.1);
  border: 1px solid #1c48e7;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  padding: 0px;
  &:hover,
  &:focus,
  &:active {
    background: rgba(28, 72, 231, 0.1);
  }
  img {
    margin: 0 auto;
    background: transparent;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border: none;
    }
  }
}

.reject-icon {
  background: rgba(244, 41, 41, 0.1);
  border: 1px solid #f42929;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  padding: 0px;
  &:hover,
  &:focus,
  &:active {
    background: rgba(244, 41, 41, 0.1);
    border: 1px solid #f42929;
  }
  img {
    margin: 0 auto;
    background: transparent;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border: none;
    }
  }
}

.status-request {
  border-radius: 4px;
  width: 67px;
  height: 33px;
  font-weight: 400;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
}
.status-accept {
  background: #d5edfe;
  color: #2263cb;
}

.status-reject {
  background: #fee9de;
  color: #ea9617;
}

.status-expired {
  background: #c0c0c0;
  color: #272122;
}

.company-request-checkbox .ant-checkbox-inner {
  border: 1.5px solid #2e2e2e;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.reject-button.ant-btn[disabled] {
  border: 1px solid #a3bdfc;
  color: #a3bdfc;
  background: #ffffff;
}

.accept-button.ant-btn[disabled] {
  border: 1px solid #a3bdfc;
  color: #ffffff;
  background: #a3bdfc;
}

.w-detail-content {
  width: calc(100% - 150px);
}

.tab-category {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1c48e7;
  }

  .ant-tabs-ink-bar {
    background: #1c48e7;
  }

  .ant-tabs-nav {
    &::before {
      border-bottom: unset;
    }
  }
}

.modal-category {
  .ant-modal-content {
    border-radius: 8px;

    svg {
      fill: #fff;
    }

    .ant-modal-header {
      background: #1c48e7;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .ant-modal-title {
        color: #ffffff;
        font-size: 20px;
      }
    }

    .ant-modal-body {
      .form {
        .form_item {
          margin-bottom: 24px;
          .language {
            color: #1c48e7;
          }

          .label_form {
            color: #2e2e2e;
            font-size: 16px;
            letter-spacing: 0.5px;

            span {
              color: #ca3f4b;
              font-weight: 400;
            }
          }

          .input_form {
            border-radius: 8px;
            border: 1px solid #c0c0c0;
            height: 48px;
          }

          .input_form_textarea {
            height: 120px !important;
          }
        }

        .button_item {
          &:first-child {
            color: #272122;
            background: #eaeaea;
          }

          &:last-child {
            background: #a3bdfc;
          }
        }
      }
    }
  }
}

.faq-list {
  .faq-list-item {
    .question {
      width: 34px;
      height: 49px;
    }
    .main {
      text-align: left;

      .main-title {
        color: #2b2b2b;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.25px;
        margin-bottom: 16px;
      }

      .main-content {
        color: #818181;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }
}

.form-create-faq {
  .language {
    color: #1c48e7;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.25px;
  }

  .input_form_textarea {
    height: 120px !important;
  }
}

.company-request-status {
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
}

.notification-list {
  .ant-table-wrapper .ant-table-tbody tr {
    td {
      &:nth-child(3) {
        white-space: pre-line !important;
      }
    }
  }
}
