.header_lp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 60px;
  width: 100%;
  gap: 20px;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: all 0.3s;

  &.header_lp_black {
    background: #3c3c3c;
  }

  .logo {
    flex: 1.95;
    display: inline-flex;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 1000;

    img {
      margin-right: 10px;
      width: 45px;
    }
  }

  .logo_blue {
    color: #253858;
  }

  .nav_btn {
    border-radius: 4px;
    background: #1c48e7;
    box-shadow: 0px 4px 10px 0px rgba(28, 72, 231, 0.5);
    padding: 14px;
    position: relative;
    z-index: 1000;
  }

  .menu_button {
    display: flex;
    align-items: center;
    gap: 20px;

    .menu {
      display: inline-flex;
      align-items: center;
      gap: 40px;
      color: #fff;
      // font-family: 'Inter', sans-serif;

      .menu_item {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }

      .language {
        position: relative;
        .language_base {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .language_globe {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }

        .language_popup {
          position: absolute;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);

          width: 100%;
          border: 1px solid #bdbdbd;
          border-radius: 5px;
          background-color: #fff;

          .language_popup_item {
            font-size: 14px;
            color: #212529;
            text-align: left;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background: #1c48e7;
              color: #ffffff;
            }
          }
        }
      }
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }

    .button_login {
      color: #fff;
      // font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      background-color: #1c48e7;
      padding: 13px 28px;
      border-radius: 4px;
    }
  }

  .menu_mobile {
    right: -100%;
    display: block;
    position: fixed;
    top: 0px;
    background: rgb(255, 255, 255);
    height: 100vh;
    width: 100%;
    overflow: auto;
    transition: all 0.3s ease 0s;
    z-index: 100;

    padding: 80px 16px 40px;

    .line {
      width: 100%;
      height: 1px;
      margin: 20px 0;
      background: #dcdcdc;
    }

    .wrap_login {
      .wrap_avatar {
        display: flex;
        align-items: center;

        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 12px;
          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
          }
        }

        .avatar_info {
          .name {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          .mail {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }

      .button_login {
        color: #fff;
        // font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        background-color: #1c48e7;
        padding: 13px 28px;
        border-radius: 4px;
        width: fit-content;
        box-shadow: 0px 4px 10px 0px rgba(28, 72, 231, 0.5);
      }
    }

    .menu {
      color: #2b2b2b;
      // font-family: 'Inter', sans-serif;
      display: grid;
      gap: 16px;

      .menu_item {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        cursor: pointer;
      }
    }

    .language {
      position: relative;
      .language_base {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        .language_globe {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      .language_popup {
        position: absolute;
        top: 27px;
        left: 0;
        width: fit-content;
        border: 1px solid #bdbdbd;
        border-radius: 5px;
        background-color: #fff;

        .language_popup_item {
          font-size: 14px;
          color: #212529;
          text-align: left;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            background: #1c48e7;
            color: #ffffff;
          }
        }
      }
    }

    .go_to_cms {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .logout {
      color: #da3131;
      // font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      display: inline-flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }
  }

  .menu_mobile_show {
    right: 0;
  }
}

@media screen and (max-width: 1199px) {
  .header_lp {
    padding: 10px 16px;

    .menu_button {
      .menu {
        gap: 24px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .header_lp {
    .logo {
      font-size: 22px;

      img {
        width: 32px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .header_lp {
    padding: 10px 16px;

    .logo {
      line-height: normal;
    }

    .nav_btn {
      padding: 8px;
    }
  }
}
