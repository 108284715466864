.payment-bgr {
    background: url('/src/assets/images/payment-bgr.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
}

@media only screen and (max-width: 1366px) {
    .payment-bgr {
        height: 800px;
    }
}
