.payment-modal {
    .button-form {
        width: 100%;
    }
}

.loading-modal {
    .ant-modal-content {
        &::after {
            content: '';
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
}